/* src/AdminLogin.css */

.admin-login-page {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('/public/banner1.jpg'); /* Ensure this path is correct */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.admin-login-container {
  background: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  max-width: 400px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.admin-login-container h2 {
  margin-bottom: 2rem;
  color: #333;
}

.admin-login-container input {
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.admin-login-container button {
  width: 100%;
  padding: 0.75rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}

.admin-login-container button:hover {
  background-color: #0056b3;
}

.admin-login-container .link {
  display: block;
  margin-top: 1rem;
  color: #007bff;
  text-decoration: none;
}

.admin-login-container .link:hover {
  text-decoration: underline;
}
