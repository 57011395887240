.testimonial-box.next {
    animation: slideNext 0.5s ease-in-out;
  }
  
  .testimonial-box.prev {
    animation: slidePrev 0.5s ease-in-out;
  }
  
  @keyframes slideNext {
    from {
      transform: translateX(100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  @keyframes slidePrev {
    from {
      transform: translateX(-100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  @keyframes slideLeft {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideOutLeft {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.testimonial-slide {
  animation: slideLeft 0.5s forwards;
}

.testimonial-slide-out {
  animation: slideOutLeft 0.5s forwards;
}

.scrolling-bar {
  width: 100%;
  background-color: rgb(238, 228, 228); /* Red background */
  /* color: white; */
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden;
  position: relative;
  height: 40px;
  display: flex;
  align-items: center;
   padding: 10px 0; /* Add some padding to space it out from the edges */
   
}
.scrolling-text {
  position: relative;
  animation: scroll-text 15s linear infinite, color-change 5s linear infinite; /* Add color-change animation */
 /*  display: flex;
  align-items: center; */
 
  display: inline-block;
  animation-play-state: running; /* Default running state */
  font-weight: bold;        /* Make text bold */
  font-style: italic;       /* Make text italic */
  font-size: medium;
}
/* Pause the animation when hovering */
.scrolling-text:hover {
  animation-play-state: paused;
}

@keyframes scroll-text {
  0% {
    transform: translateX(100vw); /* Start position (outside the screen) */
  }
  100% {
    transform: translateX(-100%); /* End position (completely off to the left) */
  }
}

@keyframes color-change {
  0% {
    color: #FF5733;
  }
  25% {
    color: #33FF57;
  }
  50% {
    color: #3357FF;
  }
  75% {
    color: #FF33A1;
  }
  100% {
    color: #FFDD33;
  }
}


#advertisements {
  margin-top: 20px;
  text-align: center;
}

.advertisements-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  padding: 10px;
}

.advertise-card {
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 8px;
  flex: 1 1 calc(33.333% - 20px); /* 3 cards per row */
  max-width: 300px; /* Limits card width for consistency */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  text-align: center;
}

.advertise-card h3 {
  margin: 10px 0;
}

.advertise-card p {
  font-size: 0.9rem;
  color: #555;
  margin: 10px 0;
}

/* Images container */
.advertise-images {
  display: flex;
  gap: 5px;
  justify-content: center;
  width: 100%;
  height: 200px; /* Consistent height for image row */
  overflow: hidden; /* Ensures images don’t exceed card dimensions */
}

.advertise-images img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Maintains aspect ratio and fills container */
  border-radius: 5px;
}

/* Responsive adjustments */
@media (max-width: 992px) {
  .advertise-card {
    flex: 1 1 calc(50% - 20px); /* 2 cards per row on medium screens */
  }
}

@media (max-width: 768px) {
  .advertise-card {
    flex: 1 1 100%; /* 1 card per row on small screens */
  }

  .advertise-images {
    height: 150px; /* Adjust height for mobile screens */
  }
}