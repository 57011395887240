/* src/admin/Register.css */
.register-page {
     background-image: url('/public/reg.jpg'); 
    background-size: cover;
    background-position: center;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px; /* Add padding to avoid cutting off the content on mobile */
}

.register-container {
    background-color: rgba(255, 255, 255, 0.85);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    max-width: 100%; /* Ensures it fits within the screen width */
    width: 100%; /* Fills available width on small screens */
    margin-top: 16px; /* Adds margin to prevent truncation */
}
